import React from 'react';
import Loadable from 'react-loadable';
import { Alert } from 'reactstrap';
import qs from 'qs';

import Spinner from './components/common/Loading';

function Loading({ isLoading, timedOut, error }) {
  if (isLoading) {
    return <Spinner />;
  } if (timedOut) {
    return <Alert color="danger">Component loader timed out!</Alert>;
  }

  return (
    <Alert color="danger">
      Failed to load component:
      {error.toString()}
    </Alert>
  );
}

const OfferDetails = Loadable({
  loader: () => import('./components/offer-details'),
  loading: Loading,
});

const NewOffer = Loadable({
  loader: () => import('./routes/NewOffer'),
  loading: Loading,
});

const NewCompany = Loadable({
  loader: () => import('./routes/NewCompany'),
  loading: Loading,
});

const NewUser = Loadable({
  loader: () => import('./routes/NewUser'),
  loading: Loading,
});

const Offers = Loadable({
  loader: () => import('components/offers'),
  loading: Loading,
});

const ShowDocument = Loadable({
  loader: () => import('./routes/ShowDocument'),
  loading: Loading,
});

const Documents = Loadable({
  loader: () => import('components/documents'),
  loading: Loading,
});

const Companies = Loadable({
  loader: () => import('components/companies'),
  loading: Loading,
});

const ShowCompany = Loadable({
  loader: () => import('./routes/ShowCompany'),
  loading: Loading,
});

const DistributionChannels = Loadable({
  loader: () => import('components/distribution-channels'),
  loading: Loading,
});

const EmailTemplates = Loadable({
  loader: () => import('components/email-templates'),
  loading: Loading,
});

const ShowEmailTemplate = Loadable({
  loader: () => import('./routes/ShowEmailTemplate'),
  loading: Loading,
});

const NewEmailTemplate = Loadable({
  loader: () => import('./routes/NewEmailTemplate'),
  loading: Loading,
});

const PreviewEmailTemplate = Loadable({
  loader: () => import('./routes/PreviewEmailTemplate'),
  loading: Loading,
});

const Emails = Loadable({
  loader: () => import('components/emails'),
  loading: Loading,
});

const SendEmail = Loadable({
  loader: () => import('./routes/SendEmail'),
  loading: Loading,
});

const ShowEmail = Loadable({
  loader: () => import('./routes/ShowEmail'),
  loading: Loading,
});

const Sms = Loadable({
  loader: () => import('components/sms'),
  loading: Loading,
});

const SmsSendSetting = Loadable({
  loader: () => import('components/sms/send-settings'),
  loading: Loading,
});

const NewSmsSendSetting = Loadable({
  loader: () => import('components/sms/send-settings/new-send-settings'),
  loading: Loading,
});

const SendSms = Loadable({
  loader: () => import('./routes/SendSms'),
  loading: Loading,
});

const ShowSms = Loadable({
  loader: () => import('./routes/ShowSms'),
  loading: Loading,
});

const Places = Loadable({
  loader: () => import('components/places'),
  loading: Loading,
});

const ShowPlaceItem = Loadable({
  loader: () => import('./routes/ShowPlaceItem'),
  loading: Loading,
});

const PlaceList = Loadable({
  loader: () => import('components/place-list'),
  loading: Loading,
});

const ShowPlaceListItem = Loadable({
  loader: () => import('./routes/ShowPlaceListItem'),
  loading: Loading,
});

const DetailsFixer = Loadable({
  loader: () => import('components/details-fixer'),
  loading: Loading,
});

const ShowDetailFixer = Loadable({
  loader: () => import('./routes/ShowDetailFixer'),
  loading: Loading,
});

const Activations = Loadable({
  loader: () => import('components/activations'),
  loading: Loading,
});

const ShowActivation = Loadable({
  loader: () => import('./routes/ShowActivation'),
  loading: Loading,
});

const CustomFields = Loadable({
  loader: () => import('components/custom-fields'),
  loading: Loading,
});

const ServiceTypes = Loadable({
  loader: () => import('components/serviceTypes'),
  loading: Loading,
});


const ListAffiliateFees = Loadable({
  loader: () => import('./routes/ListAffiliateFees'),
  loading: Loading,
});

const ListAffiliateStatements = Loadable({
  loader: () => import('./routes/ListAffiliateStatements'),
  loading: Loading,
});

const ProfileActivations = Loadable({
  loader: () => import('components/profile-activations'),
  loading: Loading,
});

const Profile = Loadable({
  loader: () => import('./routes/Profile'),
  loading: Loading,
});

const Users = Loadable({
  loader: () => import('components/users'),
  loading: Loading,
});

const ShowUser = Loadable({
  loader: () => import('./routes/ShowUser'),
  loading: Loading,
});

const People = Loadable({
  loader: () => import('components/people'),
  loading: Loading,
});

const Persons = Loadable({
  loader: () => import('components/persons'),
  loading: Loading,
});

const PersonLeases = Loadable({
  loader: () => import('components/persons/movements'),
  loading: Loading,
});

const DuplicationManager = Loadable({
  loader: () => import('components/persons/duplicationManager'),
  loading: Loading,
});


const ProfilePeople = Loadable({
  loader: () => import('components/people/people-profile'),
  loading: Loading,
});

const ShowPerson = Loadable({
  loader: () => import('./routes/ShowPerson'),
  loading: Loading,
});

const ShowPersonItem = Loadable({
  loader: () => import('./routes/ShowPersonItem'),
  loading: Loading,
});

const ListImportSources = Loadable({
  loader: () => import('components/importSources'),
  loading: Loading,
});

const ListImportCredentials = Loadable({
  loader: () => import('components/importCredentials'),
  loading: Loading,
});

const NewImport = Loadable({
  loader: () => import('./routes/NewImport'),
  loading: Loading,
});

const NewImportSource = Loadable({
  loader: () => import('./routes/NewImportSource'),
  loading: Loading,
});

const ShowImportSource = Loadable({
  loader: () => import('./routes/ShowImportSource'),
  loading: Loading,
});

const ListImports = Loadable({
  loader: () => import('components/imports'),
  loading: Loading,
});

const ShowImport = Loadable({
  loader: () => import('./routes/ShowImport'),
  loading: Loading,
});

const ListImportJobs = Loadable({
  loader: () => import('./routes/ListImportJobs'),
  loading: Loading,
});

const ShowImportJobLog = Loadable({
  loader: () => import('./routes/ShowImportJobLog'),
  loading: Loading,
});

const UrlShorts = Loadable({
  loader: () => import('components/url-shorts'),
  loading: Loading,
});

const ListCryptKeys = Loadable({
  loader: () => import('./routes/ListCryptKeys'),
  loading: Loading,
});

const NewCryptKey = Loadable({
  loader: () => import('./routes/NewCryptKey'),
  loading: Loading,
});

const ShowCryptKey = Loadable({
  loader: () => import('./routes/ShowCryptKey'),
  loading: Loading,
});

const ListCryptData = Loadable({
  loader: () => import('./routes/ListCryptData'),
  loading: Loading,
});

const NewCryptData = Loadable({
  loader: () => import('./routes/NewCryptData'),
  loading: Loading,
});

const ShowCryptData = Loadable({
  loader: () => import('./routes/ShowCryptData'),
  loading: Loading,
});

const TriggerAdmin = Loadable({
  loader: () => import('./routes/TriggerAdmin'),
  loading: Loading,
});

const Dealers = Loadable({
  loader: () => import('components/dealers'),
  loading: Loading,
});

const NewDealer = Loadable({
  loader: () => import('./routes/NewDealer'),
  loading: Loading,
});

const DealerDetails = Loadable({
  loader: () => import('./routes/ShowDealer'),
  loading: Loading,
});

const ZipCodeGroups = Loadable({
  loader: () => import('./components/zipcode-groups'),
  loading: Loading,
});

const NewZipCodeGroup = Loadable({
  loader: () => import('./components/zipcode-groups/new-zipcode-group'),
  loading: Loading,
});

const EditZipCodeGroup = Loadable({
  loader: () => import('./components/zipcode-groups/edit-zipcode-group'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: '/loading', component: Spinner },
  { path: '/offers/new', component: NewOffer, perm: 'create:offers' },
  { path: '/offers/show/:id/:section?/:sectionId?', component: OfferDetails, perm: 'update:offers' },
  { path: '/offers', component: Offers, perm: 'read:offers' },
  { path: '/offers/distribution-channels', component: DistributionChannels, perm: 'superadmin' },
  { path: '/zipcodegroups', component: ZipCodeGroups, perm: 'read:properties' },
  { path: '/zipcodegroups/new', component: NewZipCodeGroup, perm: 'create:properties' },
  { path: '/zipcodegroups/edit/:id', component: EditZipCodeGroup, perm: 'update:properties' },
  { path: '/companies', component: Companies, perm: 'read:companies' },
  { path: '/companies/new', component: NewCompany, perm: 'create:companies' },
  { path: '/companies/show/:id/:section?/:sectionId?', component: ShowCompany, perm: 'update:companies' },
  { path: '/documents', component: Documents, perm: 'superadmin' },
  { path: '/documents/show/:id/:section?', component: ShowDocument, perm: 'superadmin' },
  { path: '/contact/email-templates', component: EmailTemplates, perm: 'superadmin' },
  { path: '/contact/email-templates/show/:id/:section?', component: ShowEmailTemplate, perm: 'superadmin' },
  { path: '/contact/email-templates/new', component: NewEmailTemplate, perm: 'superadmin' },
  { path: '/contact/email-templates/preview/:id', component: PreviewEmailTemplate, perm: 'superadmin' },
  { path: '/contact/emails', component: Emails, perm: 'superadmin' },
  { path: '/contact/emails/send', component: SendEmail, perm: 'superadmin' },
  { path: '/contact/emails/show/:id/:section?/:sectionId?', component: ShowEmail, perm: 'superadmin' },
  { path: '/contact/sms', component: Sms, perm: 'superadmin' },
  { path: '/contact/sms/send', component: SendSms, perm: 'superadmin' },
  { path: '/contact/sms/show/:id/:section?', component: ShowSms, perm: 'superadmin' },
  { path: '/contact/sms', component: Sms, perm: 'superadmin' },
  { path: '/contact/send-settings/sms', component: SmsSendSetting, perm: 'superadmin' },
  { path: '/contact/send-settings/sms/new', component: NewSmsSendSetting, perm: 'superadmin' },
  { path: '/custom-fields/show/:path*', exact: false, component: CustomFields, perm: 'superadmin' },
  { path: '/service-types', exact: false, component: ServiceTypes, perm: 'superadmin' },
  { path: '/properties', component: Places, perm: 'read:properties' },
  { path: '/properties/show/:id/:section?', component: ShowPlaceItem, perm: 'read:properties' },
  { path: '/place-list', component: PlaceList, perm: 'read:properties' },
  { path: '/invalidator', component: DetailsFixer, perm: 'superadmin' },
  { path: '/invalidator/:type/:id', component: ShowDetailFixer, perm: 'superadmin' },
  { path: '/place-list/show/:id/:section?', component: ShowPlaceListItem, perm: 'read:properties' },
  { path: '/activations', component: Activations, perm: 'read:activations' },
  { path: '/activations/show/:id/:section?', component: ShowActivation, perm: 'update:activations' },
  { path: '/activations/affiliates/fees', component: ListAffiliateFees, perm: 'read:affiliate-fees' },
  { path: '/activations/affiliates/statements', component: ListAffiliateStatements, perm: 'read:affiliate-statements' },
  { path: '/profile/activations', component: ProfileActivations, perm: 'profile' },
  { path: '/profile/people', component: ProfilePeople, perm: 'profile' },
  { path: '/profile/:section?/:sectionId?', component: Profile, perm: 'profile' },
  { path: '/users/show', component: Users, perm: 'read:users' },
  { path: '/users/show/new', component: NewUser, perm: 'create:users' },
  { path: '/users/show/:id/:section?', component: ShowUser, perm: 'read:users' },
  { path: '/people', component: People, perm: 'superadmin' },
  { path: '/people/show/:id/:section?', component: ShowPerson, perm: 'superadmin' },
  { path: '/persons', component: Persons, perm: 'superadmin' },
  { path: '/persons/show/:id/:section?', component: ShowPersonItem, perm: 'superadmin' },
  { path: '/persons/merge', component: DuplicationManager, perm: 'superadmin' },
  { path: '/leases', component: PersonLeases, perm: 'superadmin' },
  { path: '/import/sources', component: ListImportSources, perm: 'superadmin' },
  { path: '/import/sources/new', component: NewImportSource, perm: 'superadmin' },
  { path: '/import/sources/show/:id/:section?', component: ShowImportSource, perm: 'superadmin' },
  { path: '/import/credentials', component: ListImportCredentials, perm: 'superadmin' },
  { path: '/import/imports', component: ListImports, perm: 'superadmin' },
  { path: '/import/imports/new/:sourceId?', component: NewImport, perm: 'superadmin' },
  { path: '/import/imports/show/:id/:section?', component: ShowImport, perm: 'superadmin' },
  { path: '/import/jobs', component: ListImportJobs, perm: 'superadmin' },
  { path: '/import/jobs/logs/:job_name', component: ShowImportJobLog, perm: 'superadmin' },
  { path: '/url-shorts', component: UrlShorts, perm: 'read:urlshorts' },
  { path: '/crypt/keys', component: ListCryptKeys, perm: 'superadmin' },
  { path: '/crypt/keys/new', component: NewCryptKey, perm: 'superadmin' },
  { path: '/crypt/keys/show/:id/:section?', component: ShowCryptKey, perm: 'superadmin' },
  { path: '/crypt/data', component: ListCryptData, perm: 'superadmin' },
  { path: '/crypt/data/new', component: NewCryptData, perm: 'superadmin' },
  { path: '/crypt/data/show/:id/:section?', component: ShowCryptData, perm: 'superadmin' },
  { path: '/triggers/:section?/:sectionId?', component: TriggerAdmin, perm: 'manage:global-triggers' },
  { path: '/dealers', component: Dealers, perm: 'superadmin' },
  { path: '/dealers/new', component: NewDealer, perm: 'superadmin' },
  { path: '/dealers/:id/:section?/:sectionId?', component: DealerDetails, perm: 'superadmin' },
];

export const redirects = [
  { path: '/', to: '/activations' },
  { path: '/offers/show', to: '/offers' },
  { path: '/companies/show', to: '/companies' },
  { path: '/people/show', to: '/people' },
  { path: '/users', to: '/users/show' },
  { path: '/recommendations', exact: false, to: '/offers/recommendations' },
  { path: '/emails/show', to: '/emails' },
  { path: '/email-templates', exact: false, to: '/emails/email-templates' },
  { path: '/distribution-channels', exact: false, to: '/offers/distribution-channels' },
  { path: '/companies/documents', to: '/documents' },
  { path: '/custom-fields', to: '/custom-fields/show' },
  { path: '/affiliates', exact: false, to: '/activations/affiliates' },
  { path: '/crypt', to: '/crypt/keys' },
  { path: '/import', to: '/import/sources' },
];

export const routeBuilders = {
  persons: {
    list: '/persons',
    view: id => `/persons/show/${id}`,
    merge: (query = {}) => `persons/merge?${qs.stringify(query)}`,
    leases: id => `persons/show/${id}/leases`,
  },
};

export const getRoutePath = {
  newImport: sourceId => `/import/imports/new${sourceId ? `/${sourceId}` : ''}`,
  viewImport: ({ importId, section }) => `/import/imports/show/${importId}${section ? `/${section}` : ''}`,
};

export default routes;
